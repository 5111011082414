var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_goods_receipt_checklist_detail") } },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                "label-align": "left",
                "wrapper-col": { span: 12 },
                "label-col": { span: 9 }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      !!_vm.detail.checklistNumber
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t("lbl_checklist_number") }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.detail.checklistNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detail, "checklistNumber", $$v)
                                  },
                                  expression: "detail.checklistNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_purchase_order_number") }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.purchaseOrderNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "purchaseOrderNumber", $$v)
                              },
                              expression: "detail.purchaseOrderNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_supplier_name") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.supplierName,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "supplierName", $$v)
                              },
                              expression: "detail.supplierName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_branch") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.branchWarehouse,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "branchWarehouse", $$v)
                              },
                              expression: "detail.branchWarehouse"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_receive_date") } },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                              "show-time": "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.form.receiveDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receiveDate", $$v)
                              },
                              expression: "form.receiveDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_supplier_do_number"),
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.supplierDeliveryOrderNo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.detail,
                                  "supplierDeliveryOrderNo",
                                  $$v
                                )
                              },
                              expression: "detail.supplierDeliveryOrderNo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_supplier_bill_address"),
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.supplierBillToAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.detail,
                                  "supplierBillToAddress",
                                  $$v
                                )
                              },
                              expression: "detail.supplierBillToAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_created_by"),
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.createdBy,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "createdBy", $$v)
                              },
                              expression: "detail.createdBy"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_status")
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.status,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "status", $$v)
                              },
                              expression: "detail.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            "label-align": "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: { value: _vm.detail.description }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.description,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "description", $$v)
                              },
                              expression: "detail.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              "data-source": _vm.detail.receiveItems,
              "row-key": "id",
              columns: _vm.columns,
              size: "small",
              pagination: {
                showSizeChanger: true,
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              scroll: { x: "calc(100% + 500px)" }
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(text || "-") + " ")
                  ])
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")
                  ])
                }
              },
              {
                key: "boolean",
                fn: function(text) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("DisplayBoolean", {
                        attrs: { value: text, mode: "yes" }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "notes",
                fn: function(text, row) {
                  return [
                    _c(
                      "a-badge",
                      { attrs: { count: row.itemAssets.length } },
                      [
                        _c("a-button", {
                          attrs: { icon: "bars" },
                          on: {
                            click: function($event) {
                              return _vm.viewItemAssets(row.itemAssets)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-3 text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.$can("read", "inventory-receive")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.print
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "50%",
            title: _vm.$tc("lbl_asset_item", _vm.modal.data.length)
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onCancel } },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modal.show,
            callback: function($$v) {
              _vm.$set(_vm.modal, "show", $$v)
            },
            expression: "modal.show"
          }
        },
        [
          _c("a-table", {
            attrs: {
              "data-source": _vm.modal.data,
              columns: _vm.columnsAsset,
              "row-key": function(r, i) {
                return i
              },
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              size: "small",
              scroll: { x: "calc(100% + 500px)" }
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(text || "-") + " ")
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }