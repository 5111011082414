














































































































































import { DisplayBoolean } from "@/components/DisplayBoolean";
import { useBlob, useReceiveItem } from "@/hooks";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import {
  ReceivingItemAssetRequestDto,
  ReceivingItemDraftResponseDto,
} from "@/models/interface/receive-item";
import { FormValue } from "@/store/goodsReceiptChecklist.store";
import printJS from "print-js";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    DisplayBoolean,
  },
  computed: {
    ...mapGetters({
      detail: "goodsReceiptChecklistStore/getDetailGrDraft",
      form: "goodsReceiptChecklistStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "goodsReceiptChecklistStore/resetStore",
    }),
  },
})
export default class DetailPage extends Vue {
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;

  @Prop({ default: "", required: true, type: String })
  id!: string; // GR id

  detail!: ReceivingItemDraftResponseDto;
  form!: FormValue;
  resetStore!: () => void;

  columns = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_qty"),
      dataIndex: "qtyPO",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_qty"),
      dataIndex: "qty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_receipt_location"),
      dataIndex: "locationReceived",
      width: "350px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_track_as_asset"),
      dataIndex: "trackAsAsset",
      scopedSlots: { customRender: "boolean" },
    },
    {
      title: this.$t("lbl_notes"),
      scopedSlots: { customRender: "notes" },
    },
  ];

  columnsAsset = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  modal = {
    show: false,
    data: [] as Array<ReceivingItemAssetRequestDto>,
    toggle(): void {
      this.show = !this.show;
    },
  };

  loading = {
    print: false,
  };

  viewItemAssets(items: Array<ReceivingItemAssetRequestDto>): void {
    this.modal.data = items || [];
    this.modal.toggle();
  }

  onCancel(): void {
    this.modal.data = [];
    this.modal.toggle();
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.goods-receipt-checklist",
    });
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    const { printGrChecklistReport } = useReceiveItem();
    this.loading.print = true;
    printGrChecklistReport(this.id)
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }
}
